import { Switch } from '@mui/material';
import { ethers } from 'ethers';
import { unitrollerAbi } from 'src/abi';
import { usePools } from 'src/hooks/usePools';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { UseAsCollateralModal } from 'src/components/modals/UseAsCollateralModal';
import { BToken } from 'src/types/asset';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useBTokenSummary } from 'src/hooks/useBTokenSummary';
import debug from 'debug';

const log = debug('components:CollateralSwitch');

export const CollateralSwitch = ({ asset }: { asset: BToken }) => {
  const setModal = useSetModal();
  const { walletProvider } = useWeb3State();
  const { selectedPool } = usePools();
  const bTokenSummary = useBTokenSummary(asset.address);
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const { inMarket, underlying } = asset;

  async function handleSwitchClick(event: React.MouseEvent) {
    event.preventDefault();

    setModal({
      key: 'confirm',
      title: inMarket ? 'Disable as Collateral' : 'Enable as Collateral',
      body: (
        <UseAsCollateralModal
          asset={asset}
          bTokenSummary={bTokenSummary}
          accountBorrowingTotalUsd={accountBorrowingTotalUsd}
          accountBorrowLimitTotalUsd={accountBorrowLimitTotalUsd}
        />
      ),
      confirmText: inMarket
        ? `disable ${underlying.symbol} as collateral`
        : `use ${underlying.symbol} as collateral`,
      onConfirm: enterOrExitMarket,
      onClose: () => setModal(null),
    });
  }

  async function enterOrExitMarket() {
    setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

    try {
      if (!walletProvider) return;
      const signer = await walletProvider.getSigner();
      const contract = new ethers.Contract(
        selectedPool.contracts.unitroller,
        unitrollerAbi,
        signer,
      );
      const tx = inMarket
        ? await contract.exitMarket(asset.address)
        : await contract.enterMarkets([asset.address]);
      log('tx: ', tx);
      setModal({
        key: 'loader',
        title: inMarket ? 'Disable as collateral' : 'Enable as collateral',
      });
      await tx.wait();
      setModal(null);
    } catch (err) {
      setModal(null);
      console.error('Change collateral status action failed:', err);
      throw err;
    }
  }

  return <Switch checked={inMarket} onClick={handleSwitchClick} />;
};
