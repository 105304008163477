import { useParams } from 'react-router-dom';
import { useBTokenSummary } from './useBTokenSummary';
import { useMemo } from 'react';
import { BToken } from '../types/asset';
import { BN } from '../utils/bigNumber';
import { useAppChain } from '../providers/AppChainProvider';
import { useBoostToken } from '../providers/BoostTokenProvider';

export const useTokenDistributionApy = (type: 'supply' | 'borrow') => {
  const { assetAddress } = useParams();
  const boostToken = useBoostToken();
  const bTokenSummary = useBTokenSummary(assetAddress);
  const [{ chainConfig }] = useAppChain();

  const distApy = useMemo(() => {
    if (!bTokenSummary) return null;

    const { price, metadata: bToken } = bTokenSummary;

    if (!price) return;

    const isSupply = type === 'supply';
    const boostSpeedPerBlock = isSupply
      ? bToken.boostSupplySpeedPerBlock
      : bToken.boostBorrowSpeedPerBlock;
    const boostSpeedPerDay = BN(boostSpeedPerBlock.toString()).times(
      chainConfig.blocksPerDay.toString(),
    );
    const bTokenMarketValue = isSupply ? getUnderlyingTotalSupply(bToken) : bToken.totalBorrows;
    const bTokenMarketUSDValue = BN(bTokenMarketValue.toString()).times(price.raw);

    console.log('underlyingTotalSupply', bTokenMarketValue);
    console.log('boostSpeedPerDay', boostSpeedPerDay.toString());
    console.log('bTokenMarketUSDValue', bTokenMarketUSDValue.toString());
    console.log('boostToken', boostToken);
  }, [bTokenSummary]);

  function getUnderlyingTotalSupply(bToken: BToken) {
    const mantissa = BN(18)
      .plus(bToken.underlying.decimals.toString())
      .minus(bToken.decimals.toString());
    return BN(bToken.totalSupply.toString())
      .times(bToken.exchangeRateCurrent.toString())
      .div(BN(10).pow(mantissa.toString()))
      .toFixed(0);
  }
};
