import { Stack, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BToken } from 'src/types/asset';
import { Supply } from './Supply';
import { Withdraw } from './Withdraw';
import { UiCard } from 'src/components/ui/UiCard';
import { titleMuiTab } from 'src/utils/cssStyles';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { BN } from 'src/utils/bigNumber';
import { useTokenMaxWithdraw } from 'src/hooks/useTokenMaxWithdraw';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';

const TABS = [
  {
    value: 'supply',
    label: 'Supply',
  },
  {
    value: 'withdraw',
    label: 'Withdraw',
  },
];

export const SWBlock = ({ asset }: { asset: BToken }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenMaxWithdraw = useTokenMaxWithdraw(asset);
  const underlyingBalance = useTokenBalance(asset.underlying.address);

  const [newTokenAmount, setNewTokenAmount] = useState('0');

  const currentTab = searchParams.get('swTab') || TABS[0].value;
  const walletBalance = underlyingBalance?.fullPrecision || '0';

  useEffect(() => {
    setNewTokenAmount('0');
  }, [currentTab]);

  function handleTabChange(e: React.SyntheticEvent, val: string) {
    searchParams.set('swTab', val);
    setSearchParams(searchParams);
  }

  return (
    <UiCard>
      <Stack spacing={2}>
        <Tabs
          value={searchParams.get('swTab') || TABS[0].value}
          sx={{ marginBottom: '2rem' }}
          onChange={handleTabChange}
          selectionFollowsFocus
        >
          {TABS.map((el) => (
            <Tab sx={titleMuiTab} key={el.value} value={el.value} label={el.label} />
          ))}
        </Tabs>
        <UiTextFieldWithMax
          maxValue={currentTab === 'supply' ? walletBalance : tokenMaxWithdraw}
          onValueChange={setNewTokenAmount}
          value={newTokenAmount}
        />
        {currentTab === 'supply' ? (
          <Supply
            asset={asset}
            value={newTokenAmount}
            setValue={(v) =>
              setNewTokenAmount(BN(v.toString()).toFixed(Number(asset.underlying.decimals)))
            }
            maxValue={walletBalance}
            errorMessage={BN(newTokenAmount).gt(walletBalance) ? 'No Funds Available' : ''}
          />
        ) : (
          <Withdraw
            asset={asset}
            value={newTokenAmount}
            setValue={(v) =>
              setNewTokenAmount(BN(v.toString()).toFixed(Number(asset.underlying.decimals)))
            }
            maxValue={tokenMaxWithdraw}
            errorMessage={BN(newTokenAmount).gt(tokenMaxWithdraw) ? 'No Balance to Withdraw' : ''}
          />
        )}
      </Stack>
    </UiCard>
  );
};
