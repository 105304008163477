import { Box, Stack, Typography } from '@mui/material';
import { useAssets, useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { Parameters } from './components/Parameters';
import { SWBlock } from './components/SWBlock';
import { BRBlock } from './components/BRBlock';
import { useTokenDistributionApy } from '../../hooks/useTokenDistributionApy';
import { useMemo } from 'react';
import { getBTokenByAddress } from 'src/utils/token';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { usePrices } from '../../providers/PricesProvider';

const marginBetweenBlocks = '2rem';

export const AssetPage = () => {
  const { fetchingPoolAssets } = useSelectedPoolAssets();
  const { loading: fetchingPrices } = usePrices();
  const { assets } = useAssets();
  const { assetAddress } = useParams();

  const bToken = useMemo(() => {
    if (!assetAddress) return;

    return getBTokenByAddress(assets, assetAddress);
  }, [assetAddress, assets]);

  const underlyingAsset = useMemo(() => {
    if (!bToken?.underlyingAssetAddress) return;

    return getBTokenByAddress(assets, bToken?.underlyingAssetAddress);
  }, [assets, bToken?.underlyingAssetAddress]);

  useTokenDistributionApy('supply');

  if (fetchingPoolAssets)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading assets...</h2>
      </Stack>
    );

  if (fetchingPrices)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading prices...</h2>
      </Stack>
    );

  return bToken ? (
    <Stack sx={{ maxWidth: '60rem', margin: '0 auto' }}>
      <Typography
        variant="h3"
        textAlign={{ sx: 'start', sm: 'center' }}
        mb={{ xs: '1rem', sm: '2rem' }}
        fontSize="2rem"
      >
        {bToken.underlying.symbol}
      </Typography>
      <Box sx={{ marginBottom: marginBetweenBlocks }}>
        <Parameters asset={bToken} underlyingAsset={underlyingAsset} />
      </Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Box sx={{ width: '100%' }}>
          <SWBlock asset={bToken} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <BRBlock asset={bToken} />
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Box>
      <Typography>Asset not found</Typography>
    </Box>
  );
};
