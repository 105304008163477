import { Outlet } from 'react-router-dom';
import { AssetsProvider } from './AssetsProvider';
import { BalancesProvider } from './BalancesProvider';
import { PricesProvider } from './PricesProvider';
import { BoostTokenProvider } from './BoostTokenProvider';
import { WalletSummaryProvider } from './WalletSummaryProvider';
import { ModalsProvider } from './ModalsProvider';
import { Modals } from 'src/components/modals/Modals';

export const RouterDependedProviders = () => {
  return (
    <BoostTokenProvider>
      <AssetsProvider>
        <BalancesProvider>
          <PricesProvider>
            <WalletSummaryProvider>
              <ModalsProvider>
                <Outlet />
                <Modals />
              </ModalsProvider>
            </WalletSummaryProvider>
          </PricesProvider>
        </BalancesProvider>
      </AssetsProvider>
    </BoostTokenProvider>
  );
};
