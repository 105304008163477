import { useEffect, useState } from 'react';
import { Box, ListItem, Button, styled, Card } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppChain } from 'src/providers/AppChainProvider';
import debug from 'debug';
import { CHAIN_LIST } from 'src/configs/chains.config';

const chainSelectLog = debug('components:ChainSelect');

const ChainSelectListbox = styled(Card)`
  position: absolute;
  width: 100%;
  min-width: 8.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  z-index: 10;
`;

export const ChainSelect = () => {
  const { pathname } = useLocation();

  const [{ chainConfig }] = useAppChain();
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);

  const handleSelect = (urlRepresentation: string) => {
    if (urlRepresentation !== chainConfig.urlRepresentation) {
      chainSelectLog('Setting new chain:', urlRepresentation);

      const newPath = pathname.replace(
        `/${chainConfig.urlRepresentation}`,
        `/${urlRepresentation}`,
      );

      navigate(newPath);
    }
    setShowOptions(false);
  };

  function documentClickListener() {
    setShowOptions(false);
  }

  useEffect(() => {
    if (!showOptions) return;

    document.addEventListener('click', documentClickListener);
    return () => document.removeEventListener('click', documentClickListener);
  }, [showOptions]);

  if (CHAIN_LIST.length === 1) return null;

  return (
    <Box position={'relative'} onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
      <Button
        variant="soft"
        color="inherit"
        onClick={() => setShowOptions(true)}
        disabled={CHAIN_LIST.length === 1}
      >
        {chainConfig.urlRepresentation}
      </Button>
      {showOptions && (
        <ChainSelectListbox>
          {CHAIN_LIST.map((chain) => (
            <ListItem
              key={chain.id}
              value={chain.urlRepresentation}
              onClick={() => handleSelect(chain.urlRepresentation)}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  background: (theme) => theme.palette.grey[100],
                },
              }}
            >
              {chain.name}
            </ListItem>
          ))}
        </ChainSelectListbox>
      )}
    </Box>
  );
};
