import { CHAIN_ID } from '../constants/chains';
import { ValueOf } from '../types/objectHelpers';

export type Pools = ValueOf<typeof POOL_CONFIGS>;
export type PoolId = keyof Pools;
export type Pool = ValueOf<Pools>;

export const POOL_CONFIGS = {
  [CHAIN_ID.ARBITRUM]: {
    main: {
      id: 'main',
      name: 'Main',
      icon: '/images/pools/main.svg',
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        compoundLens: '0xAF95D2185dEcfDbB826800Ef65e5a04AFc70b63c',
        comptroller: '0x2EcB555C6906cA787B1c394F621F90B1563d4aF7',
        unitroller: '0x2EcB555C6906cA787B1c394F621F90B1563d4aF7',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
      },
    },
    arb: {
      id: 'arb',
      name: 'ARB',
      icon: '/images/pools/arbitrum.svg',
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        comptroller: '0x0319c359C828D2CD9E52a853559d76F9958cD771',
        unitroller: '0x0319c359C828D2CD9E52a853559d76F9958cD771',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
      },
    },
    magic: {
      id: 'magic',
      name: 'MAGIC',
      icon: '/images/pools/magic.svg',
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        comptroller: '0xBfc190f10a2B3142812710e4A69b0cCE8437666e',
        unitroller: '0xBfc190f10a2B3142812710e4A69b0cCE8437666e',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
      },
    },
    gmx: {
      id: 'gmx',
      name: 'GMX',
      icon: '/images/pools/gmx.svg',
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        BOOST: '0x29f6eBF99dc038b3fEaD438a8977A10d3b389827',
        comptroller: '0x29548758476E43Ee0D3519ea239Bd6AD03cE8eF6',
        unitroller: '0x29548758476E43Ee0D3519ea239Bd6AD03cE8eF6',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
      },
    },
    crv: {
      id: 'crv',
      name: 'CRV',
      icon: '/images/pools/crv.svg',
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        BOOST: '0x45F59589dBdc0bbcA25D57aBB78b49a681b85cED',
        comptroller: '0x32aE7037a8F657e9a6ADFd5f1590C800F5c8b36A',
        unitroller: '0x32aE7037a8F657e9a6ADFd5f1590C800F5c8b36A',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
      },
    },
  },
} as const;
