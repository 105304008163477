import BigNumber from 'bignumber.js';
import { usePrices } from 'src/providers/PricesProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { BN } from 'src/utils/bigNumber';
import { calcTokenBorrowLimit } from 'src/utils/token';
import { BToken } from 'src/types/asset';

export const useMaxBorrow = (asset?: BToken) => {
  const { prices } = usePrices();

  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();

  const accountMaxBorrowUsd = BN(accountBorrowLimitTotalUsd).minus(accountBorrowingTotalUsd);

  const tokenBorrowLimit = asset ? calcTokenBorrowLimit(asset) : { raw: '0', fullPrecision: '0' };

  const accountMaxBorrow =
    asset && prices && prices[asset.address] && BN(accountMaxBorrowUsd).gt(0)
      ? BN(accountMaxBorrowUsd)
          .div(prices[asset.address].formatted)
          .toFixed(Number(asset.underlying.decimals))
      : 0;

  return BigNumber.minimum(accountMaxBorrow, tokenBorrowLimit.fullPrecision).toString();
};
