import { ethers } from 'ethers';
import { createContext, Dispatch, useContext, useState } from 'react';
import { ChainConfig, DEFAULT_CHAIN_URL } from 'src/configs/chains.config';
import { ChainUrl } from 'src/constants/chains';
import { FCC } from 'src/types/FCC';
import { extractChainFromPath, getChainConfig } from 'src/utils/chain';

/**
 * - Stores provider and all info needed to retrieve data from blockchain.
 * - Depends on the chain currently selected in the web app.
 * - Doesn't depend on the user wallet chain, whether it's connected or not.
 */
export type AppChainState = {
  appWsProvider: ethers.WebSocketProvider;
  appRpcProvider: ethers.JsonRpcProvider;
  chainConfig: ChainConfig;
};

export const getWsProvider = (chainUrl: ChainUrl) => {
  const chain = getChainConfig(chainUrl);
  return new ethers.WebSocketProvider(chain.wsProviderUrl);
};

export const getRpcProvider = (chainUrl: ChainUrl) => {
  const chain = getChainConfig(chainUrl);
  return new ethers.JsonRpcProvider(chain.rpcProviderUrl);
};

export const getUpdatedAppChain = (chainUrl: ChainUrl) => ({
  chainConfig: getChainConfig(chainUrl),
  appWsProvider: getWsProvider(chainUrl),
  appRpcProvider: getRpcProvider(chainUrl),
});

const customChainUrl = extractChainFromPath(window.location.pathname);
const initialAppChainUrl = getChainConfig(customChainUrl)?.urlRepresentation || DEFAULT_CHAIN_URL;

export const initialAppChainState: AppChainState = getUpdatedAppChain(initialAppChainUrl);

const AppChainStx = createContext<[AppChainState, Dispatch<React.SetStateAction<AppChainState>>]>([
  initialAppChainState,
  () => null,
]);

export const AppChainProvider: FCC = ({ children }) => {
  const value = useState<AppChainState>(initialAppChainState);

  return <AppChainStx.Provider value={value}>{children}</AppChainStx.Provider>;
};

export const useAppChain = () => useContext(AppChainStx);
