import { Box, Stack } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import { Dispatch, SetStateAction } from 'react';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { calculateAPY } from 'src/utils/token';
import { bTokenAbi } from '../../../abi';
import { Iconify } from '../../../components/Iconify';
import { SliderConnectedWithInput } from '../../../components/SliderConnectedWithInput';
import { useSetModal } from '../../../providers/ModalsProvider';
import { usePrices } from '../../../providers/PricesProvider';
import { useSelectedPoolWalletSummary } from '../../../providers/WalletSummaryProvider';
import { useWeb3State } from '../../../providers/Web3CtxProvider';
import { AssetParam } from './AssetParam';

const log = debug('components:Borrow');

export const Borrow = ({
  asset,
  value,
  errorMessage,
  maxValue,
  setValue,
}: {
  asset: BToken;
  value: string;
  errorMessage?: string;
  maxValue: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [{ chainConfig }] = useAppChain();
  const { walletProvider } = useWeb3State();
  const setModal = useSetModal();
  const borrowedBalance = useTokenBorrowedBalance(asset.address);
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const prices = usePrices();

  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd)
    .plus(
      BN(value)
        .times(prices.prices?.[asset.address].raw || '0')
        .div(BN(10).pow(asset.underlying.decimals.toString())),
    )
    .div(accountBorrowLimitTotalUsd)
    .times(100)
    .toFixed(2);

  async function borrow() {
    if (!walletProvider) return;

    log('call borrow', getAtomicAmount(value, Number(asset.underlying.decimals)));

    try {
      const valueWei = getAtomicAmount(value, Number(asset.underlying.decimals));
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      const tx = await bTokenContract.borrow(valueWei);

      setModal({ key: 'loader', title: `Borrowing ${value} ${asset.underlying.symbol}...` });

      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${valueWei} ${asset.underlying.symbol} successfully borrowed.`);
    } catch (err) {
      setModal(null);
      console.error('Borrow action failed:', err);
      throw err;
    }
  }

  return (
    <Stack>
      <Box sx={{ marginBottom: '1rem' }}>
        <SliderConnectedWithInput value={value} maxValue={maxValue} setValue={setValue} />
      </Box>
      <Box>
        <Stack direction={'column'} flexWrap={'wrap'}>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Borrow APY"
              value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Borrow Limit Used"
              value={
                <Stack direction="row" alignItems="center">
                  {borrowLimitUsed} %
                  {BN(accountBorrowLimitTotalUsd).gt(0) && (
                    <>
                      <Iconify
                        icon="material-symbols:arrow-forward-rounded"
                        width={24}
                        sx={{ mx: 2 }}
                      />
                      {featureBorrowLimit} %
                    </>
                  )}
                </Stack>
              }
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Currently Borrowing"
              value={`${borrowedBalance ? borrowedBalance.formatted : '0'} ${
                asset.underlying.symbol
              }`}
            />
          </Stack>
        </Stack>
      </Box>
      <UiExecuteButton
        executeLabel={errorMessage || 'Borrow'}
        onClick={borrow}
        disabled={Boolean(errorMessage) || !BN(value).gt(0)}
      />
    </Stack>
  );
};
