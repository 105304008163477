import { Box, Stack } from '@mui/material';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { BToken } from 'src/types/asset';
import { calculateAPY } from 'src/utils/token';
import { AssetParam } from './AssetParam';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { useAllowance } from 'src/hooks/useAllowance';
import { useApprove } from 'src/hooks/useApprove';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { MAX_UINT } from 'src/constants/eth';
import { Dispatch, SetStateAction } from 'react';
import { SliderConnectedWithInput } from '../../../components/SliderConnectedWithInput';
import { ethers } from 'ethers';
import { bTokenAbi, maximillonAbi } from '../../../abi';
import debug from 'debug';
import { useSetModal } from '../../../providers/ModalsProvider';
import { useSelectedPoolWalletSummary } from '../../../providers/WalletSummaryProvider';
import { usePrices } from '../../../providers/PricesProvider';
import { Iconify } from '../../../components/Iconify';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';

const log = debug('components:Repay');

export const Repay = ({
  asset,
  value,
  errorMessage,
  maxValue,
  setValue,
}: {
  asset: BToken;
  value: string;
  errorMessage?: string;
  maxValue: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [{ chainConfig }] = useAppChain();
  const { userAddress, walletProvider } = useWeb3State();
  const setModal = useSetModal();
  const borrowedBalance = useTokenBorrowedBalance(asset.address);
  const [tokenAllowance, reloadAllowance] = useAllowance(
    asset.underlyingAssetAddress,
    userAddress,
    asset.address,
  );
  const tokenApprove = useApprove(asset.underlyingAssetAddress, asset.address, 'approve');
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const prices = usePrices();

  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .minus(
          BN(value)
            .times(prices.prices?.[asset.address].raw || '0')
            .div(BN(10).pow(asset.underlying.decimals.toString())),
        )
        .div(accountBorrowLimitTotalUsd)
        .times(100)
        .toFixed(2)
    : 0;

  const needApproval =
    !asset.underlying.isEth &&
    BN(tokenAllowance).lt(getAtomicAmount(value, Number(asset.underlying.decimals)));

  const approveToSpendTokens = () => {
    return tokenApprove(MAX_UINT).finally(reloadAllowance);
  };

  async function repay() {
    if (!walletProvider) return;

    log('call repay', getAtomicAmount(value, Number(asset.underlying.decimals)));
    log('asset.underlying.isEth', asset.underlying.isEth);

    try {
      const repayValue = getAtomicAmount(value, Number(asset.underlying.decimals));
      const signer = await walletProvider.getSigner();

      let tx;

      if (asset.underlying.isEth) {
        const maximillonContract = new ethers.Contract(
          chainConfig.contracts.maximillion,
          maximillonAbi,
          signer,
        );
        tx = await maximillonContract.repayBehalf(userAddress, { value: repayValue });
      } else {
        const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);
        tx = await bTokenContract.repayBorrow(repayValue);
      }

      setModal({ key: 'loader', title: `Repaying ${value} ${asset.underlying.symbol}...` });

      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${repayValue} ${asset.underlying.symbol} successfully repaid.`);
    } catch (err) {
      setModal(null);
      console.error('Repay action failed:', err);
      throw err;
    }
  }

  return (
    <Stack>
      <Box sx={{ marginBottom: '1rem' }}>
        <SliderConnectedWithInput value={value} maxValue={maxValue} setValue={setValue} />
      </Box>
      <Box>
        <Stack direction={'column'} flexWrap={'wrap'}>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Borrow APY"
              value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Borrow Limit Used"
              value={
                <Stack direction="row" alignItems="center">
                  {borrowLimitUsed} %
                  {BN(accountBorrowLimitTotalUsd).gt(0) && (
                    <>
                      <Iconify
                        icon="material-symbols:arrow-forward-rounded"
                        width={24}
                        sx={{ mx: 2 }}
                      />
                      {featureBorrowLimit} %
                    </>
                  )}
                </Stack>
              }
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Currently Borrowing"
              value={`${borrowedBalance ? borrowedBalance.formatted : '0'} ${
                asset.underlying.symbol
              }`}
            />
          </Stack>
        </Stack>
      </Box>
      <UiExecuteButton
        executeLabel={errorMessage || 'Repay'}
        onClick={needApproval ? approveToSpendTokens : repay}
        needApproval={needApproval}
        disabled={Boolean(errorMessage) || !BN(value).gt(0)}
      />
    </Stack>
  );
};
