import { ethers } from 'ethers';
import { createContext, useContext, useEffect, useState } from 'react';
import { unitrollerAbi } from '../abi';
import { usePools } from '../hooks/usePools';
import { Asset } from '../types/asset';
import { FCC } from '../types/FCC';
import { fetchTokensInfoRaw } from '../utils/token';
import { useAppChain } from './AppChainProvider';

const BoostTokenProviderInitCtx: Asset = {
  address: '',
  decimals: 0,
  isEth: false,
  name: '',
  symbol: '',
};

const BoostTokenProviderCtx = createContext(BoostTokenProviderInitCtx);

export const BoostTokenProvider: FCC = ({ children }) => {
  const { selectedPool } = usePools();
  const [{ appRpcProvider }] = useAppChain();

  const [tokenParams, setTokenParams] = useState<Asset>(BoostTokenProviderInitCtx);

  useEffect(() => {
    if (!selectedPool) return;
    if (selectedPool.id !== 'main') return;
    const unitrollerContract = new ethers.Contract(
      selectedPool.contracts.unitroller,
      unitrollerAbi,
      appRpcProvider,
    );

    unitrollerContract.getCompAddress().then((address) =>
      fetchTokensInfoRaw(appRpcProvider, [address]).then((resp) =>
        setTokenParams({
          name: resp[0],
          symbol: resp[1],
          decimals: resp[2],
          address: address.toLowerCase(),
        }),
      ),
    );
  }, [selectedPool, appRpcProvider]);

  return (
    <BoostTokenProviderCtx.Provider value={tokenParams}>{children}</BoostTokenProviderCtx.Provider>
  );
};

export const useBoostToken = () => useContext(BoostTokenProviderCtx);
