import { useMemo } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { usePrices } from 'src/providers/PricesProvider';
import { BN } from 'src/utils/bigNumber';

export const PoolStats = ({ ...props }: StackProps) => {
  const { assets } = useSelectedPoolAssets();
  const { prices } = usePrices();

  const stats = useMemo(() => {
    let totalSupply = '0';
    let totalBorrow = '0';

    if (!assets || !prices) return { totalSupply, totalBorrow };

    Object.values(assets)
      .filter((asset) => !asset.mintPaused && !asset.borrowPaused)
      .forEach((asset) => {
        if (!prices[asset.address]) return;
        const tokenTotalSupply = BN(asset.totalSupply)
          .times(prices[asset.address].raw)
          .times(asset.exchangeRateCurrent)
          .div(BN(10).pow(18 - Number(asset.decimals)))
          .div(BN(10).pow(Number(asset.underlying.decimals) * 2))
          .div(BN(10).pow(Number(asset.decimals)));
        totalSupply = tokenTotalSupply.plus(totalSupply).toString();

        const tokenTotalBorrow = BN(asset.totalBorrows)
          .times(prices[asset.address].raw)
          .div(BN(10).pow(Number(asset.underlying.decimals) * 2));
        totalBorrow = tokenTotalBorrow.plus(totalBorrow).toString();
      });

    return { totalSupply, totalBorrow };
  }, [assets, prices]);

  return (
    <Stack {...props}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={'1rem'}>
        <Typography variant="subtitle1">
          Pool Total Supply:{' '}
          <Typography component="span" variant="subtitle1" sx={{ color: 'text.secondary' }}>
            ${BN(stats.totalSupply).toFixed(2)}
          </Typography>
        </Typography>
        <Typography variant="subtitle1">
          Pool Total Borrow:{' '}
          <Typography component="span" variant="subtitle1" sx={{ color: 'text.secondary' }}>
            ${BN(stats.totalBorrow).toFixed(2)}
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
};
