import { Call, Contract, Provider } from 'ethcall';
import { ethers } from 'ethers';
import { createContext, useContext, useEffect, useState } from 'react';
import { boosterOracleAbi, multiFeedsAbi } from '../abi';
import { useImmutableCallback } from '../hooks/useActualRef';
import { AssetPrices } from '../types/asset';
import { FCC } from '../types/FCC';
import { BN, getDisplayAmount } from '../utils/bigNumber';
import { getBTokenByAddress } from '../utils/token';
import { useAppChain } from './AppChainProvider';
import { useAssets } from './AssetsProvider';

const pricesProviderInit: {
  loading: boolean;
  prices: AssetPrices | null;
} = {
  loading: true,
  prices: null,
};

const PricesProviderCtx = createContext(pricesProviderInit);

export const PricesProvider: FCC = ({ children }) => {
  const [{ chainConfig, appRpcProvider, appWsProvider }] = useAppChain();
  const { assets, fetchingPoolAssets } = useAssets();

  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState<AssetPrices | null>(null);
  const [multiFeedsAddress, setMultiFeedsAddress] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (fetchingPoolAssets) return;

    fetchPrices();
  }, [assets, fetchingPoolAssets]);

  useEffect(() => {
    if (!chainConfig) return;

    setPrices(null);
    setMultiFeedsOracleAddress();
  }, [chainConfig]);

  useEffect(() => {
    startPriceUpdateTracking();
  }, [multiFeedsAddress]);

  function setMultiFeedsOracleAddress() {
    const bOracleContract = new ethers.Contract(
      chainConfig.contracts.oracle,
      boosterOracleAbi,
      appRpcProvider,
    );
    bOracleContract.multidataFeeds().then(setMultiFeedsAddress);
  }

  async function fetchPrices(updateAll = false) {
    let addresses: string[] = [];

    addresses = Object.values(assets)
      .map((poolAssets) => Object.values(poolAssets).map((asset) => asset.address))
      .flat();

    if (!updateAll && prices) {
      addresses = addresses.filter((address) => !Object.keys(prices).includes(address));
    }

    if (addresses.length === 0) {
      setLoading(false);
      return;
    }

    const ethCallProvider = new Provider(chainConfig.id, appRpcProvider);
    const ethCallOracleContract = new Contract(chainConfig.contracts.oracle, boosterOracleAbi);
    const calls: Call[] = addresses.map(ethCallOracleContract.getUnderlyingPrice);

    const rawPrices: bigint[] = await ethCallProvider.all(calls);

    const _prices: AssetPrices = {};

    rawPrices.forEach((price, i) => {
      if (!addresses) return;

      const address = addresses[i];
      const bToken = getBTokenByAddress(assets, address);

      if (!bToken) {
        console.warn('BTOKEN NOT FOUND');
        return;
      }

      const decimals = Number(bToken.underlying.decimals);
      const raw = BN(price.toString())
        .times(BN(10).pow(decimals * 2))
        .div(BN(10).pow(36))
        .toFixed(0);
      const formatted = getDisplayAmount(raw, { decimals });

      _prices[address] = {
        bTokenAddress: address,
        address: bToken.address,
        formatted,
        raw,
      };
    });

    setPrices((prev) => ({ ...prev, ..._prices }));
    setLoading(false);
  }

  function startPriceUpdateTracking() {
    if (!multiFeedsAddress) return;

    const multiFeedsContract = new ethers.Contract(multiFeedsAddress, multiFeedsAbi, appWsProvider);

    multiFeedsContract.on('MetricUpdated', priceUpdateCallback);
  }

  const priceUpdateCallback = useImmutableCallback(() => fetchPrices(true));

  return (
    <PricesProviderCtx.Provider value={{ loading, prices }}>{children}</PricesProviderCtx.Provider>
  );
};

export const usePrices = () => useContext(PricesProviderCtx);
