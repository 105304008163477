import { Stack, Tab, Tabs } from '@mui/material';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UiCard } from 'src/components/ui/UiCard';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { useMaxBorrow } from 'src/hooks/useMaxBorrow';
import { useTokenBalance, useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { titleMuiTab } from 'src/utils/cssStyles';
import { Borrow } from './Borrow';
import { Repay } from './Repay';

const TABS = [
  {
    value: 'borrow',
    label: 'Borrow',
  },
  {
    value: 'repay',
    label: 'Repay',
  },
];

export const BRBlock = ({ asset }: { asset: BToken }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenMaxBorrow = useMaxBorrow(asset);
  const tokenBalance = useTokenBalance(asset.underlying.address);
  const borrowedBalance = useTokenBorrowedBalance(asset.address);

  const [newTokenAmount, setNewTokenAmount] = useState('0');

  const currentTab = searchParams.get('brTab') || TABS[0].value;

  const tokenMaxRepay =
    tokenBalance && borrowedBalance
      ? BigNumber.minimum(tokenBalance.fullPrecision, borrowedBalance.fullPrecision).toString()
      : '0';

  useEffect(() => {
    setNewTokenAmount('0');
  }, [currentTab]);

  function handleTabChange(e: React.SyntheticEvent, val: string) {
    searchParams.set('brTab', val);
    setSearchParams(searchParams);
  }

  return (
    <UiCard>
      <Stack spacing={2}>
        <Tabs
          value={currentTab}
          sx={{ marginBottom: '2rem' }}
          onChange={handleTabChange}
          selectionFollowsFocus
        >
          {TABS.map((el) => (
            <Tab sx={titleMuiTab} key={el.value} value={el.value} label={el.label} />
          ))}
        </Tabs>
        <UiTextFieldWithMax
          maxValue={currentTab === 'borrow' ? tokenMaxBorrow : tokenMaxRepay}
          value={newTokenAmount}
          onValueChange={setNewTokenAmount}
        />
        {currentTab === 'borrow' ? (
          <Borrow
            asset={asset}
            value={newTokenAmount}
            errorMessage={BN(newTokenAmount).gt(tokenMaxBorrow) ? 'Borrowing Limit Reached' : ''}
            maxValue={tokenMaxBorrow}
            setValue={(v) =>
              setNewTokenAmount(BN(v.toString()).toFixed(Number(asset.underlying.decimals)))
            }
          />
        ) : (
          <Repay
            asset={asset}
            value={newTokenAmount}
            errorMessage={BN(newTokenAmount).gt(tokenMaxRepay) ? 'No Funds Available' : ''}
            maxValue={tokenMaxRepay}
            setValue={(v) =>
              setNewTokenAmount(BN(v.toString()).toFixed(Number(asset.underlying.decimals)))
            }
          />
        )}
      </Stack>
    </UiCard>
  );
};
