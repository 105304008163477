import { Call, Contract, Provider } from 'ethcall';
import { ethers } from 'ethers';
import copy from 'fast-copy';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Pool, PoolId } from 'src/configs/pools.config';
import { useImmutableCallback } from 'src/hooks/useActualRef';
import { createMatrix } from 'src/utils/arrayHelpers';
import { compoundLensAbi, unitrollerAbi } from '../abi';
import { CHAIN_LIST } from '../configs/chains.config';
import { usePools } from '../hooks/usePools';
import { Asset, AssetProvider, BToken, BTokenMetadata, BTokenMetadataRaw } from '../types/asset';
import { FCC } from '../types/FCC';
import { isAddressesEq } from '../utils/compareAddresses';
import { ETH_AS_TOKEN, isBETH } from '../utils/eth';
import { fetchTokensInfoRaw } from '../utils/token';
import { useAppChain } from './AppChainProvider';
import { useWeb3State } from './Web3CtxProvider';

const assetsStructure = {} as AssetProvider;

CHAIN_LIST.forEach((chain) => {
  Object.values(chain.pools).forEach((pool) => (assetsStructure[pool.id] = {}));
});

const AssetsProviderCtx = createContext({ assets: assetsStructure, fetchingPoolAssets: true });

export const AssetsProvider: FCC = ({ children }) => {
  const { userAddress } = useWeb3State();
  const [{ appRpcProvider, appWsProvider, chainConfig }] = useAppChain();
  const { selectedPool } = usePools();

  const [fetchingPoolAssets, setFetchingPoolAssets] = useState(true);
  const [assets, setAssets] = useState(assetsStructure);

  const ethcallProvider = useMemo(
    () => new Provider(chainConfig.id, appRpcProvider),
    [chainConfig, appRpcProvider],
  );

  const getUnitrollerContract = useCallback(
    (selectedPool: Pool) => {
      if (!selectedPool) return;
      if (!appRpcProvider) return;

      return new ethers.Contract(selectedPool.contracts.unitroller, unitrollerAbi, appRpcProvider);
    },
    [appRpcProvider],
  );

  const marketEnteredListener = useImmutableCallback(
    (tokenAddress: string, walletAddress: string) => {
      if (!userAddress) return;
      if (!selectedPool) return;

      if (userAddress.toLowerCase() === walletAddress.toLowerCase()) {
        const copyAssets = copy(assets);
        copyAssets[selectedPool.id][tokenAddress.toLowerCase()].inMarket = true;
        setAssets(copyAssets);
      }
    },
  );

  const marketExitedListener = useImmutableCallback(
    (tokenAddress: string, walletAddress: string) => {
      if (!userAddress) return;
      if (!selectedPool) return;

      if (userAddress.toLowerCase() === walletAddress.toLowerCase()) {
        const copyAssets = copy(assets);
        copyAssets[selectedPool.id][tokenAddress.toLowerCase()].inMarket = false;
        setAssets(copyAssets);
      }
    },
  );

  useEffect(() => {
    fetchPoolAssetsInfo(chainConfig.pools['main']);
  }, []);

  useEffect(() => {
    if (!getUnitrollerContract(selectedPool)) return;

    fetchPoolAssetsInfo(selectedPool);
  }, [getUnitrollerContract, chainConfig, selectedPool]);

  useEffect(() => {
    if (!userAddress) setAssets((prevState) => clearInMarket(prevState));
  }, [userAddress]);

  useEffect(() => {
    if (!userAddress) return;

    const unitrollerContract = getUnitrollerContract(selectedPool);
    if (!unitrollerContract) return;
    if (fetchingPoolAssets) return;
    if (!selectedPool) return;
    if (!Object.keys(assets[selectedPool.id]).length) return;

    unitrollerContract.getAssetsIn(userAddress).then((resp: string[]) => {
      const assetsWithIn = copy(clearInMarket(assets));

      Object.keys(assetsWithIn[selectedPool.id]).forEach((asset) => {
        assetsWithIn[selectedPool.id][asset].inMarket = false;
      });

      resp.forEach((address) => {
        assetsWithIn[selectedPool.id][address.toLowerCase()].inMarket = true;
      });

      setAssets(assetsWithIn);
    });
  }, [userAddress, fetchingPoolAssets, getUnitrollerContract, selectedPool]);

  async function fetchPoolAssetsInfo(selectedPool: Pool) {
    const unitrollerContract = getUnitrollerContract(selectedPool);

    if (!unitrollerContract) return;
    if (!selectedPool) return;
    if (Object.keys(assets[selectedPool.id]).length) return;

    const unitrollerWsContract = new ethers.Contract(
      selectedPool.contracts.unitroller,
      unitrollerAbi,
      appWsProvider,
    );

    unitrollerWsContract.on('MarketEntered', marketEnteredListener);
    unitrollerWsContract.on('MarketExited', marketExitedListener);

    setFetchingPoolAssets(true);

    const compoundLensContract = new ethers.Contract(
      chainConfig.contracts.compoundLens,
      compoundLensAbi,
      appRpcProvider,
    );

    let bETH: BToken | undefined;
    const allMarkets = (await unitrollerContract.getAllMarkets()) as string[];

    const unitrollerEthCallContract = new Contract(
      selectedPool.contracts.unitroller,
      unitrollerAbi,
    );

    const guardianPausedCalls: Call[] = [];

    allMarkets.forEach((market) => {
      guardianPausedCalls.push(unitrollerEthCallContract.mintGuardianPaused(market));
      guardianPausedCalls.push(unitrollerEthCallContract.borrowGuardianPaused(market));
    });

    const [marketsDataResp, guardianPausedResp] = await Promise.all([
      compoundLensContract.cTokenMetadataAll([...allMarkets]),
      ethcallProvider.all(guardianPausedCalls) as Promise<boolean[]>,
    ]);

    const splitedGuardianPausedCalls = createMatrix(guardianPausedResp, 2);

    const guardianPausedMap = {} as Record<string, boolean[]>;

    allMarkets.forEach((market, i) => {
      guardianPausedMap[market.toLowerCase()] = splitedGuardianPausedCalls[i];
    });

    const marketsMetadata: BTokenMetadata[] = marketsDataResp
      .map(processBTokenMetadata)
      .filter((bToken: BTokenMetadata) => {
        if (isBETH(selectedPool, bToken.address)) {
          bETH = {
            ...bToken,
            address: selectedPool.contracts.bETH.toLowerCase(),
            symbol: 'bETH',
            name: 'Booster ETH',
            decimals: 8,
            underlying: ETH_AS_TOKEN,
          };
          return false;
        }
        return true;
      });

    const tokens = marketsMetadata.map((el) => [el.address, el.underlyingAssetAddress]).flat();

    const tokensInfoRaw = await fetchTokensInfoRaw(appRpcProvider, tokens);

    const tokensInfoSplitted = createMatrix(tokensInfoRaw, 3);

    const tokensInfoFormatted = tokensInfoSplitted.map((token, i) => {
      const replacedSymbol =
        chainConfig.tokensSymbolsReplacement[tokens[i].toLowerCase()] ||
        chainConfig.tokensSymbolsReplacement[tokens[i]];

      return {
        name: token[0],
        symbol: replacedSymbol || token[1],
        decimals: token[2],
        address: tokens[i].toLowerCase(),
      };
    });

    const bTokens: Record<string, BToken> = {};

    marketsMetadata.forEach((metadata, i) => {
      const bTokenInfo = tokensInfoFormatted.find((el) =>
        isAddressesEq(el.address, metadata.address),
      ) as Asset;
      const underlying = tokensInfoFormatted.find((el) =>
        isAddressesEq(el.address, metadata.underlyingAssetAddress),
      ) as Asset;
      const [mintPaused, borrowPaused] = guardianPausedMap[metadata.address];

      bTokens[metadata.address] = {
        ...metadata,
        ...bTokenInfo,
        mintPaused,
        borrowPaused,
        underlying,
      };
    });

    if (bETH) bTokens[bETH.address] = bETH;

    setAssets((prevState) => ({ ...prevState, [selectedPool.id]: bTokens }));
    setFetchingPoolAssets(false);
  }

  function processBTokenMetadata(
    bToken: BTokenMetadataRaw,
  ): Omit<BTokenMetadata, 'borrowPaused' | 'mintPaused'> {
    return {
      address: bToken[0].toLowerCase(),
      borrowCap: bToken[16].toString(),
      borrowRatePerBlock: bToken[3].toString(),
      collateralFactorMantissa: bToken[10].toString(),
      boostBorrowSpeedPerBlock: bToken[15].toString(),
      boostSupplySpeedPerBlock: bToken[14].toString(),
      exchangeRateCurrent: bToken[1].toString(),
      isListed: bToken[9],
      reserveFactorMantissa: bToken[4].toString(),
      supplyRatePerBlock: bToken[2].toString(),
      totalBorrows: bToken[5].toString(),
      totalCash: bToken[8].toString(),
      totalReserves: bToken[6].toString(),
      totalSupply: bToken[7].toString(),
      underlyingAssetAddress: bToken[11].toLowerCase(),
      inMarket: false,
    };
  }

  function clearInMarket(assets: AssetProvider) {
    const tokens = assetsStructure;
    Object.keys(assets).forEach((pool) => {
      Object.keys(assets[pool as PoolId]).forEach((asset) => {
        tokens[pool as PoolId][asset] = {
          ...assets[pool as PoolId][asset],
          inMarket: false,
        };
      });
    });
    return tokens;
  }

  return (
    <AssetsProviderCtx.Provider value={{ assets, fetchingPoolAssets }}>
      {children}
    </AssetsProviderCtx.Provider>
  );
};

export const useAssets = () => useContext(AssetsProviderCtx);

export const useSelectedPoolAssets = () => {
  const { assets, fetchingPoolAssets } = useAssets();
  const { selectedPool } = usePools();

  return {
    assets: assets[selectedPool?.id],
    fetchingPoolAssets,
  };
};
