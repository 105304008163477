import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Slider, Stack, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CollateralSwitch } from 'src/components/CollateralSwitch';
import { UiCard } from 'src/components/ui/UiCard';
import { PoolId } from 'src/configs/pools.config';
import { useResponsive } from 'src/hooks/useResponsive';
import { useAppChain } from 'src/providers/AppChainProvider';
import { usePrices } from 'src/providers/PricesProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { muiSlider } from 'src/utils/cssStyles';
import { calcTokenBorrowLimit, calculateAPY } from 'src/utils/token';
import { UiQuestion } from '../../../components/ui/UiQuestion';
import tooltipsDic from 'src/configs/tooltips.json';

export const Parameters = ({
  asset,
  underlyingAsset,
}: {
  asset: BToken;
  underlyingAsset?: BToken;
}) => {
  const { poolId } = useParams();
  const isDesktop = useResponsive('up', 'md');

  const [{ chainConfig }] = useAppChain();
  const { accountBorrowLimitTotalUsd, accountBorrowingTotalUsd } = useSelectedPoolWalletSummary();
  const { userAddress } = useWeb3State();
  const { prices } = usePrices();

  const poolName = poolId ? chainConfig.pools[poolId as PoolId].name : '';
  const collateralFactor = `${BN(asset.collateralFactorMantissa.toString())
    .times(100)
    .div(1e18)
    .toString()} %`;

  const borrowLimit = calcTokenBorrowLimit(asset);

  const borrowLimitUsed = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 0;

  const oraclePrice = useMemo(() => {
    const bTokenPriceUsd = `$${prices?.[asset.address].formatted || '0'}`;

    if (!underlyingAsset) return bTokenPriceUsd;

    const exchangeRate = BN(underlyingAsset.exchangeRateCurrent)
      .div(BN(10).pow(18 - Number(underlyingAsset.decimals)))
      .div(BN(10).pow(Number(underlyingAsset.underlying.decimals)))
      .toFixed(4)
      .toString();

    return (
      <Stack
        direction="column"
        ml={{ xs: 'auto', md: 'unset' }}
        textAlign={{ xs: 'right', md: 'left' }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: '1.2rem', sm: '1.6rem' },
            wordWrap: 'break-word',
            wordBreak: 'break-all',
          }}
        >
          {bTokenPriceUsd}
        </Typography>
        <Typography color={'text.secondary'} variant="subtitle2" lineHeight="0.8">
          {exchangeRate} {underlyingAsset.underlying.symbol}
        </Typography>
      </Stack>
    );
  }, [asset.address, prices, underlyingAsset]);

  return (
    <UiCard>
      <Stack spacing={2}>
        <Typography mr="auto" variant="h4">
          Parameters
        </Typography>
        <Stack flexDirection={`${isDesktop ? 'row' : 'column'}`} columnGap={6}>
          <Stack width={'100%'}>
            <Slider
              min={0}
              max={100}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `${value}%`}
              disabled
              value={Number(borrowLimitUsed)}
              sx={{
                ...muiSlider,
                '.MuiSlider-thumb': {
                  display: 'none',
                },
              }}
              style={{ marginLeft: 0, width: '100%' }}
            />
            <AssetParam
              name={BN(borrowLimitUsed).lt(100) ? 'Borrow limit used' : 'Borrow limit reached'}
              value={`${borrowLimitUsed} %`}
              variant="big"
              tooltipMessage={tooltipsDic.borrowLimitUsed}
            />
          </Stack>

          <Stack width={'100%'}>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <AssetParam name="Pool" value={poolName} />
              {userAddress && (
                <AssetParam
                  name="Use as collateral"
                  value={<CollateralSwitch asset={asset} />}
                  tooltipMessage={tooltipsDic.useAsCollateral}
                />
              )}
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <AssetParam
                name="Supply APY"
                value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
              />
              <AssetParam
                name="Borrow APY"
                value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
              />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <AssetParam
                name="Collateral Factor"
                value={collateralFactor}
                tooltipMessage={tooltipsDic.collateralFactor}
              />
              <AssetParam
                name="Borrow Limit"
                value={`${borrowLimit.formatted} ${asset.underlying.symbol}`}
                tooltipMessage={tooltipsDic.borrowLimitUsed}
              />
            </Stack>
            <AssetParam name="Oracle Price" value={oraclePrice} />
          </Stack>
        </Stack>
      </Stack>
    </UiCard>
  );
};

const AssetParam: FC<{
  name: string;
  value: string | bigint | ReactJSXElement;
  variant?: 'normal' | 'big';
  tooltipMessage?: string;
}> = ({ name, value, variant = 'normal', tooltipMessage }) => {
  return React.isValidElement(value) ? (
    <Stack
      sx={{
        width: { xs: '100%', md: '50%' },
        flexWrap: 'wrap',
        paddingRight: { xs: '0', md: '2rem' },
        alignItems: { xs: 'center', md: 'flex-start' },
      }}
      direction={{ xs: 'row', md: 'column' }}
      mb="1.5rem"
    >
      <Typography sx={{ color: 'text.secondary' }}>
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      {value}
    </Stack>
  ) : variant === 'normal' ? (
    <Stack
      sx={{
        width: { xs: '100%', md: '60%' },
        flexWrap: 'wrap',
        paddingRight: { xs: '0', md: '2rem' },
        alignItems: { xs: 'center', md: 'flex-start' },
        justifyContent: { xs: 'space-between' },
        ':first-of-type': {
          width: { md: '40%' },
        },
        whiteSpace: 'nowrap',
      }}
      direction={{ xs: 'row', md: 'column' }}
      mb="1.5rem"
    >
      <Typography sx={{ color: 'text.secondary' }}>
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.6rem' },
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}
      >
        {value.toString()}
      </Typography>
    </Stack>
  ) : (
    <Stack width={{ xs: '100%', md: '50%' }} mb="2rem">
      <Typography variant="h5" sx={{ color: 'text.secondary' }} fontWeight="normal">
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      <Typography variant="h3" sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
        {value.toString()}
      </Typography>
    </Stack>
  );
};
