import { ValueOf } from 'src/types/objectHelpers';

export type ChainUrl = ValueOf<typeof CHAIN_URL_REPRESENTATION>;

export type ChainId = ValueOf<typeof CHAIN_ID>;

export const CHAIN_ID = {
  ARBITRUM: 42161,
} as const;

export const CHAIN_URL_REPRESENTATION = {
  [CHAIN_ID.ARBITRUM]: 'arbitrum',
} as const;
