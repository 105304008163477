import debug from 'debug';
import { Call, Contract, Provider } from 'ethcall';
import { Block } from 'ethers';
import copy from 'fast-copy';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { bTokenAbi, erc20Abi } from 'src/abi';
import { ZERO_ADDRESS } from 'src/constants/eth';
import { useImmutableCallback } from 'src/hooks/useActualRef';
import { AssetBalance, AssetBalancesMap, BToken } from 'src/types/asset';
import { FCC } from 'src/types/FCC';
import { getEveryNth } from 'src/utils/arrayHelpers';
import { getDisplayAmount } from 'src/utils/bigNumber';
import { INITIAL_BALANCE } from '../constants/initialSchemas';
import { isAddressesEq } from '../utils/compareAddresses';
import { useAppChain } from './AppChainProvider';
import { useAssets } from './AssetsProvider';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('components:BalancesProvider');

const balancesProviderInitCtx: { balances: AssetBalancesMap; ethBalance: AssetBalance } = {
  balances: {},
  ethBalance: INITIAL_BALANCE,
};

const BalancesProviderCtx = createContext(balancesProviderInitCtx);

export const BalancesProvider: FCC = ({ children }) => {
  const { userAddress } = useWeb3State();
  const [{ appWsProvider, appRpcProvider, chainConfig }] = useAppChain();
  const { assets, fetchingPoolAssets } = useAssets();

  const [ethBalanceBlock, setEthBalanceBlock] = useState(0);
  const [balances, setBalances] = useState(balancesProviderInitCtx.balances);
  const [ethBalance, setEthBalance] = useState(balancesProviderInitCtx.ethBalance);

  const ethcallProvider = useMemo(
    () => new Provider(chainConfig.id, appRpcProvider),
    [appRpcProvider],
  );

  useEffect(() => {
    if (fetchingPoolAssets) return;

    fetchNewTokensBalances();
  }, [assets, fetchingPoolAssets]);

  useEffect(() => {
    if (!userAddress) clearBalances();

    updateTokensBalances();
  }, [userAddress]);

  useEffect(() => {
    appWsProvider.on('block', newBlockListener);
  }, [appWsProvider]);

  const newBlockListener = useImmutableCallback((blockNumber: any) => {
    if (!userAddress) return;

    if (
      ethBalanceBlock === 0 ||
      ethBalanceBlock <= blockNumber - chainConfig.ethBalanceBlockReqDelay
    ) {
      appWsProvider.getBlock(blockNumber, true).then((block) => {
        if (!block) return;

        trackTokensBalances(block);
        setEthBalanceBlock(blockNumber);
        fetchEthBalance();
      });
    }
  });

  function trackTokensBalances(block: Block) {
    if (!userAddress) return;

    for (const tx of block.prefetchedTransactions) {
      if (tx.from === userAddress || tx.to === userAddress) {
        tx.wait().then(async (receipt) => {
          if (receipt && receipt.status == 1) {
            updateTokensBalances();
          }
        });
      }
    }
  }

  async function fetchNewTokensBalances() {
    if (!userAddress) return;

    const newAssets = Object.values(assets)
      .map((el) => Object.values(el))
      .flat()
      .filter((el) => !Object.keys(balances).includes(el.address.toLowerCase()));

    updateTokensBalances(newAssets);
  }

  async function updateTokensBalances(targetAssets?: BToken[]) {
    if (!userAddress) return;

    const assetsToUpdate =
      targetAssets ||
      Object.values(assets)
        .map((el) => Object.values(el))
        .flat();

    if (assetsToUpdate.length === 0) return;

    const tokens = assetsToUpdate
      .map((el) => [el.address.toLowerCase(), el.underlyingAssetAddress.toLowerCase()])
      .flat();
    const tokensDecimals = assetsToUpdate.map((el) => [el.decimals, el.underlying.decimals]).flat();
    const bTokens = getEveryNth(tokens, 2, 1);

    const [fetchedTokensBalances, fetchedAccountTokensBorrowed] = await Promise.all([
      fetchTokensBalances(tokens),
      fetchAccountBorrowedBalances(bTokens),
    ]);
    const newBalances: AssetBalancesMap = {};

    fetchedTokensBalances.forEach((tokenBalance, i) => {
      const raw = tokenBalance?.toString() || '0';
      const decimals = Number(tokensDecimals[i]);
      const fullPrecision = getDisplayAmount(raw.toString(), { decimals, cut: false });
      const formatted = getDisplayAmount(raw.toString(), { decimals });

      if (!newBalances[tokens[i]])
        newBalances[tokens[i]] = {
          wallet: INITIAL_BALANCE,
          borrowing: balances?.[tokens[i]]?.borrowing || INITIAL_BALANCE,
        };

      newBalances[tokens[i]].wallet = {
        raw,
        fullPrecision,
        formatted,
      };
    });

    fetchedAccountTokensBorrowed.forEach((tokenBalance, i) => {
      const raw = tokenBalance.toString() || '0';
      const decimals = Number(
        assetsToUpdate.find((el) => isAddressesEq(el.address, bTokens[i]))?.underlying.decimals ||
          '0',
      );
      const fullPrecision = getDisplayAmount(raw.toString(), { decimals, cut: false });
      const formatted = getDisplayAmount(raw.toString(), { decimals });

      if (!newBalances[bTokens[i]])
        newBalances[bTokens[i]] = {
          wallet: INITIAL_BALANCE,
          borrowing: INITIAL_BALANCE,
        };

      newBalances[bTokens[i]].borrowing = {
        raw,
        fullPrecision,
        formatted,
      };
    });

    log('newBalances', newBalances);

    setBalances((prevBalances) => ({ ...prevBalances, ...newBalances }));
  }

  function fetchTokensBalances(addresses: string[]) {
    const calls: Call[] = [];

    addresses.forEach((address) => {
      const contract = new Contract(address, erc20Abi);
      calls.push(contract.balanceOf(userAddress));
    });

    return ethcallProvider.tryAll(calls) as Promise<[balance: bigint]>;
  }

  async function fetchEthBalance() {
    if (!userAddress) return;

    const raw = (await appRpcProvider.getBalance(userAddress)).toString();
    const formatted = getDisplayAmount(raw.toString(), { decimals: 18 });
    const fullPrecision = getDisplayAmount(raw.toString(), { decimals: 18, cut: false });
    const newBalance = { raw, fullPrecision, formatted };

    setEthBalance((prevBalance) => {
      if (prevBalance.raw === newBalance.raw) return prevBalance;

      return newBalance;
    });
  }

  function fetchAccountBorrowedBalances(addresses: string[]) {
    const calls: Call[] = [];

    addresses.forEach((address) => {
      const contract = new Contract(address, bTokenAbi);
      calls.push(contract.borrowBalanceStored(userAddress));
    });

    return ethcallProvider.tryAll(calls) as Promise<[balance: bigint]>;
  }

  function clearBalances() {
    const newBalances = copy(balances);

    Object.values(newBalances).forEach((el) => {
      el.wallet = INITIAL_BALANCE;
      el.borrowing = INITIAL_BALANCE;
    });

    setEthBalance(INITIAL_BALANCE);
    setBalances(newBalances);
  }

  return (
    <BalancesProviderCtx.Provider value={{ balances, ethBalance }}>
      {children}
    </BalancesProviderCtx.Provider>
  );
};

export const useBalances = () => useContext(BalancesProviderCtx);

export const useTokenBalance = (tokenAddress: string) => {
  const { balances, ethBalance } = useBalances();

  if (tokenAddress === ZERO_ADDRESS) return ethBalance;

  return balances[tokenAddress]?.wallet || INITIAL_BALANCE;
};

export const useTokenBorrowedBalance = (tokenAddress: string): AssetBalance | undefined => {
  const { balances } = useBalances();

  return balances[tokenAddress]?.borrowing;
};
