import BigNumber from 'bignumber.js';
import { usePrices } from '../providers/PricesProvider';
import { BN } from 'src/utils/bigNumber';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { BToken } from 'src/types/asset';

export const useTokenMaxWithdraw = (asset?: BToken) => {
  const { prices } = usePrices();
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const tokenBalance = useTokenBalance(asset?.address || '')?.formatted || '0';

  if (!asset) return '0';
  if (!prices) return '0';

  const tokenSupplying = asset
    ? BN(tokenBalance)
        .times(asset.exchangeRateCurrent.toString())
        .div(BN(10).pow(18 - Number(asset.decimals)))
    : 0;

  const tokenSupplyingInUsd =
    asset && prices && prices[asset.address]
      ? BN(tokenSupplying)
          .times(prices[asset.address]?.raw)
          .div(BN(10).pow(Number(asset.underlying.decimals) * 2))
      : 0;

  if (!asset.inMarket) {
    return BN(tokenSupplyingInUsd)
      .times(BN(10).pow(Number(asset.underlying.decimals)))
      .div(prices[asset.address].raw)
      .toFixed(Number(asset.underlying.decimals));
  }

  const borrowLimit = BN(accountBorrowLimitTotalUsd).minus(accountBorrowingTotalUsd);
  const accountMaxWithdrawInUsd = borrowLimit.div(
    BN(Number(asset?.collateralFactorMantissa || '0')).div(1e18),
  );

  const maxWithdrawUsd = BigNumber.min(tokenSupplyingInUsd, accountMaxWithdrawInUsd);

  console.log('#############');
  console.log(
    'useTokenMaxWithdraw accountBorrowLimitTotalUsd',
    accountBorrowLimitTotalUsd.toString(),
  );
  console.log('useTokenMaxWithdraw accountBorrowingTotalUsd', accountBorrowingTotalUsd.toString());
  console.log('useTokenMaxWithdraw borrowLimit', borrowLimit.toString());
  console.log('useTokenMaxWithdraw tokenSupplyingInUsd', tokenSupplyingInUsd.toString());
  console.log('useTokenMaxWithdraw accountMaxWithdrawInUsd', accountMaxWithdrawInUsd.toString());
  console.log('useTokenMaxWithdraw maxWithdrawUsd', maxWithdrawUsd.toString());
  console.log('#############');

  if (prices[asset.address] && maxWithdrawUsd.gt(0)) {
    const val = BN(maxWithdrawUsd)
      .times(BN(10).pow(Number(asset.underlying.decimals)))
      .div(prices[asset.address].raw)
      .toFixed(Number(asset.underlying.decimals));

    return BN(val).eq(0) ? '0' : val;
  }

  return '0';
};
