import { Box, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { UiQuestion } from '../../../components/ui/UiQuestion';

type AssetParamParams = {
  name: string;
  value: ReactNode | string;
  tooltipMessage?: string;
  sx?: SxProps<Theme>;
};

export const AssetParam = ({ name, value, sx = {}, tooltipMessage }: AssetParamParams) => {
  return (
    <Box sx={sx}>
      <Typography sx={{ color: 'text.secondary' }}>
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: '1.6rem', wordWrap: 'break-word', wordBreak: 'break-all' }}
      >
        {value}
      </Typography>
    </Box>
  );
};
