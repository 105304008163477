import { Slider } from '@mui/material';
import { muiSlider } from '../utils/cssStyles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BN } from '../utils/bigNumber';

export const SliderConnectedWithInput = ({
  value,
  maxValue,
  setValue,
}: {
  value: string;
  maxValue: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if (!maxValue || !value) {
      setSliderValue(0);
      return;
    }

    if (BN(value).lte(0)) {
      setSliderValue(0);
      return;
    }

    const newValue = Number(BN(value).div(maxValue).times(100).toFixed(0));

    setSliderValue(newValue);
  }, [value, maxValue]);

  function changeSliderValueHandler(e: Event, value: number | number[]) {
    if (!maxValue) return;
    if (Array.isArray(value)) return;

    setValue(BN(maxValue).times(value).div(100).toString());
  }

  return (
    <Slider
      min={0}
      max={100}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => `${value}%`}
      value={sliderValue}
      onChange={changeSliderValueHandler}
      sx={muiSlider}
    />
  );
};
