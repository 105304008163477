import { Box, BoxProps, Button, OutlinedInput } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, FC } from 'react';
import { numberInputReg } from '../../utils/input';
import { BN } from '../../utils/bigNumber';

type Props = {
  maxValue?: string;
  value: string;
  onValueChange: (val: string) => void;
};

export const UiTextFieldWithMax: FC<Props & BoxProps> = ({
  maxValue,
  value,
  onValueChange,
  ...props
}) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let value = numberInputReg(e.target.value);

    if (maxValue && BN(value).gt(maxValue)) {
      value = maxValue;
    }

    onValueChange(value);
  }

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }} {...props}>
      <OutlinedInput
        fullWidth
        value={value}
        onChange={handleChange}
        endAdornment={
          maxValue ? (
            <Button color="inherit" onClick={() => onValueChange(maxValue)}>
              Max
            </Button>
          ) : null
        }
      />
    </Box>
  );
};
