import { ZERO_ADDRESS } from 'src/constants/eth';
import { Pool } from '../configs/pools.config';
import { Asset } from '../types/asset';
import { isAddressesEq } from './compareAddresses';

export const isBETH = (pool: Pool, address: string) => isAddressesEq(pool.contracts.bETH, address);

export const ETH_AS_TOKEN: Asset = {
  address: ZERO_ADDRESS,
  decimals: 18,
  name: 'Ethereum',
  symbol: 'ETH',
  isEth: true,
};
