import { AppBar, Button, Stack, Toolbar, useTheme } from '@mui/material';
import { Logo } from 'src/components/Logo';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { HEADER } from 'src/theme/layout-constants';
import { bgBlur } from 'src/utils/cssStyles';
import { shortenHash } from 'src/utils/ui';
import { NavHorizontal } from '../nav/NavHorizontal/NavHorizontal';
import { ChainSelect } from './ChainSelect';
import { ConnectBtn } from './ConnectBtn';

export const Header = () => {
  const { userAddress } = useWeb3State();
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        padding: '1rem 0',
        boxShadow: 'none',
        minHeight: HEADER.H_MOBILE,
        justifyContent: 'center',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      }}
    >
      <Toolbar
        sx={{
          px: { lg: 4 },
        }}
      >
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1.5}
        >
          <Logo />
          <Stack
            direction="row"
            flexGrow={1}
            justifyContent="flex-end"
            width={'100%'}
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <NavHorizontal />
            <ChainSelect />
            {userAddress && (
              <Button variant="soft" color="inherit" disabled>
                {shortenHash(userAddress)}
              </Button>
            )}
            <ConnectBtn />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
