import { ethers } from 'ethers';
import { CHAIN_ID, CHAIN_URL_REPRESENTATION } from '../constants/chains';
import { POOL_CONFIGS } from './pools.config';

const DEFAULT_CHAIN = CHAIN_ID.ARBITRUM;
export const DEFAULT_CHAIN_URL = CHAIN_URL_REPRESENTATION[DEFAULT_CHAIN];
export const DEFAULT_POOL_URL = Object.keys(POOL_CONFIGS[DEFAULT_CHAIN])[0];

export type ChainConfig = (typeof CHAIN_LIST)[number];

export const CHAIN_LIST = [
  {
    id: CHAIN_ID.ARBITRUM,
    urlRepresentation: CHAIN_URL_REPRESENTATION[CHAIN_ID.ARBITRUM],
    name: 'Arbitrum',
    explorer: 'https://arbiscan.io/',
    wsProviderUrl:
      import.meta.env.MODE === 'development'
        ? 'wss://wiser-cool-dust.arbitrum-mainnet.discover.quiknode.pro/3aad02b8f6284baab69381acd8eb5d5934855f9b/'
        : 'wss://rpc.ankr.com/arbitrum/ws/1b5b228411a377e29f3590d64cc0d936b5bfda08351650ed6f482e6017693b98',
    rpcProviderUrl: 'https://arb1.arbitrum.io/rpc',
    paramsForAdding: {
      rpcProvider: 'https://arb1.arbitrum.io/rpc',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      hexId: ethers.toBeHex(CHAIN_ID.ARBITRUM),
    },
    pools: POOL_CONFIGS[CHAIN_ID.ARBITRUM],
    blocksPerDay: 86400 / 12,
    ethBalanceBlockReqDelay: 20,
    contracts: {
      oracle: '0x9c371a9fb2fce60685a1b2aacd3187e7c5d0d150',
      compoundLens: '0xAF95D2185dEcfDbB826800Ef65e5a04AFc70b63c',
      maximillion: '0x6f6D0771d3d69B0643da3300d83B0767d03c1929',
    },
    tokensSymbolsReplacement: {
      '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 'USDC.e',
    } as Record<string, string>,
  },
] as const;
