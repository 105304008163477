import { useModal } from 'src/providers/ModalsProvider';
import { ModalSettings } from 'src/types/modal';
import { ConfirmModal } from './ConfirmModal';
import { SuccessModal } from './SuccessModal';
import { LoaderModal } from './LoaderModal';
import { ConnectWalletModal } from './ConnectWalletModal';

const renderModal = (modal: ModalSettings) => {
  switch (modal.key) {
    case 'connectWallet':
      return <ConnectWalletModal />;
    case 'confirm':
      return <ConfirmModal {...modal} />;
    case 'loader':
      return <LoaderModal title={modal.title} txHash={modal.txHash} />;
    case 'success':
      return <SuccessModal title={modal.title} txHash={modal.txHash} />;
    default:
      return null;
  }
};

export const Modals = () => {
  const modal = useModal();
  return modal ? renderModal(modal) : null;
};
