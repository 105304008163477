import { Box, Stack, useMediaQuery } from '@mui/material';
import { useAppChain } from 'src/providers/AppChainProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount, getDisplayAmount } from 'src/utils/bigNumber';
import { calculateAPY } from 'src/utils/token';
import { AssetParam } from './AssetParam';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { ethers } from 'ethers';
import { bTokenAbi } from '../../../abi';
import { useWeb3State } from '../../../providers/Web3CtxProvider';
import { useSetModal } from '../../../providers/ModalsProvider';
import debug from 'debug';
import { Dispatch, SetStateAction } from 'react';
import { SliderConnectedWithInput } from '../../../components/SliderConnectedWithInput';
import { useSelectedPoolWalletSummary } from '../../../providers/WalletSummaryProvider';
import { usePrices } from '../../../providers/PricesProvider';
import { Iconify } from '../../../components/Iconify';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import tooltipsDic from '../../../configs/tooltips.json';

const log = debug('components:Withdraw');

export const Withdraw = ({
  asset,
  value,
  errorMessage,
  maxValue,
  setValue,
}: {
  asset: BToken;
  value: string;
  errorMessage?: string;
  maxValue: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const { walletProvider } = useWeb3State();
  const [{ chainConfig }] = useAppChain();
  const setModal = useSetModal();

  const tokenBalance = useTokenBalance(asset?.address || '');
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const prices = usePrices();
  const isMobile = useMediaQuery('(max-width:425px)');

  const borrowLimitUsed = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .div(
          BN(accountBorrowLimitTotalUsd).minus(
            BN(value)
              .times(BN(asset.collateralFactorMantissa.toString()).div(1e18))
              .times(prices.prices?.[asset.address].raw || '0')
              .div(BN(10).pow(asset.underlying.decimals.toString())),
          ),
        )
        .times(100)
        .toFixed(2)
    : 0;

  const tokenSupplying = asset
    ? BN(tokenBalance.raw.toString())
        .times(asset.exchangeRateCurrent.toString())
        .div(BN(10).pow(asset.decimals.toString()))
        .div(BN(10).pow(18 - Number(asset.decimals)))
    : 0;

  async function redeem() {
    if (!walletProvider) return;

    const mantissa = BN(18).plus(asset.underlying.decimals.toString());
    const oneCTokenInUnderlying = BN(asset.exchangeRateCurrent.toString()).div(
      BN(10).pow(mantissa.toString()),
    );
    const withdrawMaxBTokenValue = tokenBalance.raw;

    log('call redeem', {
      withdrawBTokenValue: withdrawMaxBTokenValue.toString(),
      oneCTokenInUnderlying: oneCTokenInUnderlying.toString(),
      exchangeRateCurrent: asset.exchangeRateCurrent.toString(),
      asset,
      tokenBalance,
      value,
      valueEqMax: value === maxValue,
    });

    try {
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      let tx;

      if (value === maxValue) {
        tx = await bTokenContract.redeem(withdrawMaxBTokenValue.toString());
      } else {
        tx = await bTokenContract.redeemUnderlying(
          getAtomicAmount(value, Number(asset.underlying.decimals)),
        );
      }

      setModal({
        key: 'loader',
        title: `Withdrawing ${value} ${asset.underlying.symbol}...`,
      });

      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${withdrawMaxBTokenValue} ${asset.underlying.symbol} successfully withdrawed.`);
    } catch (err) {
      setModal(null);
      console.error('Withdraw action failed:', err);
      throw err;
    }
  }

  return (
    <Stack>
      <Box sx={{ marginBottom: '1rem' }}>
        <SliderConnectedWithInput value={value} maxValue={maxValue} setValue={setValue} />
      </Box>
      <Box>
        <Stack direction={'column'} flexWrap={'wrap'}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            marginBottom={'1.5rem'}
          >
            <AssetParam
              name="Supply APY"
              sx={{ marginBottom: isMobile ? '1.5rem' : '0' }}
              value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
            />
            <AssetParam
              name="Distribution APY"
              value="-%"
              tooltipMessage={tooltipsDic.distributionAPY}
            />
          </Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            marginBottom={'1.5rem'}
          >
            <AssetParam
              name="Borrow Limit Used"
              value={
                <Stack direction="row" alignItems="center">
                  {borrowLimitUsed} %
                  {BN(accountBorrowingTotalUsd).gt(0) && (
                    <>
                      <Iconify
                        icon="material-symbols:arrow-forward-rounded"
                        width={24}
                        sx={{ mx: 2 }}
                      />
                      {featureBorrowLimit} %
                    </>
                  )}
                </Stack>
              }
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Currently Supplying"
              value={`${getDisplayAmount(tokenSupplying, {
                decimals: Number(asset.underlying.decimals),
              })} ${asset.underlying.symbol}`}
            />
          </Stack>
        </Stack>
      </Box>
      <UiExecuteButton
        executeLabel={errorMessage || 'Withdraw'}
        onClick={redeem}
        disabled={Boolean(errorMessage) || !BN(value).gt(0)}
      />
    </Stack>
  );
};
