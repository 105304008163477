import { BN, getDisplayAmount } from 'src/utils/bigNumber';
import { AssetProvider, BToken, TokenInfoCallsRes } from '../types/asset';
import { Call, Contract, Provider } from 'ethcall';
import { erc20Abi } from '../abi';
import { getCurrentChainConfig } from './chain';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

export function calculateAPY(ratePerBlock: string, blocksPerDay: number) {
  const ratePerDay = BN(ratePerBlock.toString()).times(blocksPerDay).div(1e18);

  return BN(1).plus(ratePerDay).pow(365).minus(1).times(100).toFixed(2);
}

export function getBTokenByAddress(pools: AssetProvider, bTokenAddress: string) {
  const bTokens = Object.values(pools)
    .map((bTokensMap) => Object.values(bTokensMap))
    .flat();

  return bTokens.find((bToken) => bToken.address === bTokenAddress);
}

export function fetchTokensInfoRaw(appRpcProvider: ethers.JsonRpcProvider, addresses: string[]) {
  const chainConfig = getCurrentChainConfig();

  if (!chainConfig) return Promise.resolve([]);

  const ethcallProvider = new Provider(chainConfig.id, appRpcProvider);
  const calls: Call[] = [];

  addresses.forEach((address) => {
    const contract = new Contract(address, erc20Abi);
    calls.push(contract.name());
    calls.push(contract.symbol());
    calls.push(contract.decimals());
  });

  return ethcallProvider.all(calls) as Promise<TokenInfoCallsRes>;
}

export function calcSupplyingTokens(asset: BToken) {
  return BN(asset.totalSupply.toString())
    .times(asset.exchangeRateCurrent.toString())
    .div(BN(10).pow(asset.decimals.toString()))
    .div(BN(10).pow(18 - Number(asset.decimals)));
}

export function calcTokenBorrowLimit(asset: BToken) {
  const supplying = calcSupplyingTokens(asset);

  let tokenBorrowLimit: BigNumber;

  if (BN(supplying).gt(asset.borrowCap.toString()))
    tokenBorrowLimit = BN(asset.borrowCap.toString()).minus(asset.totalBorrows.toString());
  else tokenBorrowLimit = BN(supplying).minus(asset.totalBorrows.toString());

  return {
    raw: tokenBorrowLimit.toString(),
    fullPrecision: tokenBorrowLimit
      .div(BN(10).pow(asset.underlying.decimals.toString()))
      .toString(),
    formatted: getDisplayAmount(tokenBorrowLimit, { decimals: Number(asset.underlying.decimals) }),
  };
}
