import React from 'react';

import { useChainStatus } from 'src/hooks/useChainStatus';

import { StyledNetworkAttention } from './styled';
import { useAppChain } from 'src/providers/AppChainProvider';

export const NetworkAttention: React.FC = () => {
  const { isWalletConnected, isWalletChainSupported, isChainsIdsAreEq } = useChainStatus();
  const [{ chainConfig }] = useAppChain();

  async function switchNetwork() {
    if (window.ethereum && window.ethereum.isMetaMask && chainConfig) {
      const {
        paramsForAdding: { hexId, nativeCurrency },
        name,
        rpcProviderUrl,
        explorer,
      } = chainConfig;
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexId }],
        });
      } catch (switchError: any) {
        console.error(switchError);
        if (switchError['code'] === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: hexId,
                  chainName: name,
                  rpcProviderUrl,
                  nativeCurrency,
                  blockExplorerUrls: [explorer],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }
  }

  if (!chainConfig || !isWalletConnected) return null;

  if (!isWalletChainSupported || !isChainsIdsAreEq) {
    return (
      <StyledNetworkAttention onClick={switchNetwork}>
        Click to switch network to {chainConfig.name}
      </StyledNetworkAttention>
    );
  }

  return null;
};
