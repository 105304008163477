import { Box, Stack, useMediaQuery } from '@mui/material';
import { useAppChain } from 'src/providers/AppChainProvider';
import { BToken } from 'src/types/asset';
import { calculateAPY } from 'src/utils/token';
import { AssetParam } from './AssetParam';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { useAllowance } from 'src/hooks/useAllowance';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { useApprove } from 'src/hooks/useApprove';
import { MAX_UINT } from 'src/constants/eth';
import { ethers } from 'ethers';
import { bTokenAbi } from '../../../abi';
import { useSetModal } from '../../../providers/ModalsProvider';
import debug from 'debug';
import { Dispatch, SetStateAction } from 'react';
import { SliderConnectedWithInput } from '../../../components/SliderConnectedWithInput';
import { useSelectedPoolWalletSummary } from '../../../providers/WalletSummaryProvider';
import { usePrices } from '../../../providers/PricesProvider';
import { Iconify } from '../../../components/Iconify';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import tooltipsDic from '../../../configs/tooltips.json';

const log = debug('components:Supply');

export const Supply = ({
  asset,
  value,
  errorMessage,
  maxValue,
  setValue,
}: {
  asset: BToken;
  value: string;
  errorMessage?: string;
  maxValue: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [{ chainConfig }] = useAppChain();
  const { userAddress, walletProvider } = useWeb3State();
  const prices = usePrices();
  const underlyingBalance = useTokenBalance(asset.underlying.address);
  const tokenApprove = useApprove(asset.underlyingAssetAddress, asset.address, 'approve');
  const [tokenAllowance, reloadAllowance] = useAllowance(
    asset.underlyingAssetAddress,
    userAddress,
    asset.address,
  );
  const setModal = useSetModal();
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const isMobile = useMediaQuery('(max-width:425px)');

  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .div(
          BN(accountBorrowLimitTotalUsd).plus(
            BN(value)
              .times(BN(asset.collateralFactorMantissa.toString()).div(1e18))
              .times(prices.prices?.[asset.address].raw || '0')
              .div(BN(10).pow(asset.underlying.decimals.toString())),
          ),
        )
        .times(100)
        .toFixed(2)
    : 0;

  const needApproval =
    !asset.underlying.isEth &&
    BN(tokenAllowance).lt(getAtomicAmount(value, Number(asset.underlying.decimals)));

  function approveToSpendTokens() {
    return tokenApprove(MAX_UINT).finally(reloadAllowance);
  }

  async function supply() {
    if (!walletProvider) return;

    log('call supply', getAtomicAmount(value, Number(asset.underlying.decimals)));

    try {
      const supplyValue = getAtomicAmount(value, Number(asset.underlying.decimals));
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      let tx;

      if (asset.underlying.isEth) {
        tx = await bTokenContract.mint(0, { value: supplyValue });
      } else {
        tx = await bTokenContract.mint(supplyValue);
      }

      setModal({ key: 'loader', title: `Supplying ${value} ${asset.underlying.symbol}...` });

      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${supplyValue} ${asset.underlying.symbol} successfully supplied.`);
    } catch (err) {
      setModal(null);
      console.error('Supply action failed:', err);
      throw err;
    }
  }

  return (
    <Stack>
      <Box sx={{ marginBottom: '1rem' }}>
        <SliderConnectedWithInput value={value} maxValue={maxValue} setValue={setValue} />
      </Box>
      <Box>
        <Stack direction={'column'} flexWrap={'wrap'}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            marginBottom={'1.5rem'}
          >
            <AssetParam
              name="Supply APY"
              value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
              sx={{ marginBottom: isMobile ? '1.5rem' : '0' }}
            />
            <AssetParam
              name="Distribution APY"
              value="-%"
              tooltipMessage={tooltipsDic.distributionAPY}
              sx={{ textAlign: isMobile ? 'left' : 'right' }}
            />
          </Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            marginBottom={'1.5rem'}
          >
            <AssetParam
              name="Borrow Limit Used"
              value={
                <Stack direction="row" alignItems="center">
                  {borrowLimitUsed} %
                  <Iconify
                    icon="material-symbols:arrow-forward-rounded"
                    width={24}
                    sx={{ mx: 2 }}
                  />
                  {featureBorrowLimit} %
                </Stack>
              }
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
            <AssetParam
              name="Wallet Balance"
              value={`${underlyingBalance ? underlyingBalance.formatted : 0} ${
                asset.underlying.symbol
              }`}
            />
          </Stack>
        </Stack>
      </Box>
      <UiExecuteButton
        executeLabel={errorMessage || 'Supply'}
        onClick={needApproval ? approveToSpendTokens : supply}
        needApproval={needApproval}
        disabled={!needApproval && (Boolean(errorMessage) || !BN(value).gt(0))}
      />
    </Stack>
  );
};
