import { useAppChain } from '../providers/AppChainProvider';
import { useParams } from 'react-router-dom';
import { PoolId } from '../configs/pools.config';

export const usePools = () => {
  const [{ chainConfig }] = useAppChain();
  const { poolId } = useParams() as { poolId: PoolId };

  const poolsList = Object.values(chainConfig.pools);
  const selectedPool = chainConfig.pools[poolId];

  return { poolsList, selectedPool };
};
