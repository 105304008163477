import { PoolStats } from 'src/components/PoolStats';
import { WalletStats } from 'src/components/WalletStats';
import { useChainStatus } from 'src/hooks/useChainStatus';
import { PoolSelector } from 'src/pages/home/components/PoolSelector';
import { PoolAssetsList } from './components/PoolAssetsList';

export const Home = () => {
  const { isWalletConnected } = useChainStatus();

  return (
    <>
      <PoolSelector />
      <PoolStats mb={2} mt={1} />
      {isWalletConnected && <WalletStats mb={2} />}
      <PoolAssetsList />
    </>
  );
};
