import { createContext, useContext, useEffect, useState } from 'react';
import { CHAIN_LIST } from 'src/configs/chains.config';
import { PoolId } from 'src/configs/pools.config';
import { FCC } from 'src/types/FCC';
import { BN } from 'src/utils/bigNumber';
import { useSelectedPoolAssets } from './AssetsProvider';
import { usePrices } from 'src/providers/PricesProvider';
import { useBalances } from 'src/providers/BalancesProvider';
import { usePools } from 'src/hooks/usePools';
import debug from 'debug';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('components:AccountsProvider');

type PoolWalletSummary = {
  accountSupplyingTotalUsd: string;
  accountBorrowingTotalUsd: string;
  accountBorrowLimitTotalUsd: string;
  accountDebtUsd: string;
};

type WalletSummaryCtx = Record<PoolId, PoolWalletSummary>;

const initWalletSummary = {} as WalletSummaryCtx;

CHAIN_LIST.forEach((chain) => {
  Object.values(chain.pools).forEach(
    (pool) =>
      (initWalletSummary[pool.id] = {
        accountSupplyingTotalUsd: '0',
        accountBorrowingTotalUsd: '0',
        accountBorrowLimitTotalUsd: '0',
        accountDebtUsd: '0',
      }),
  );
});

const AccountsProviderCtx = createContext({ walletSummary: initWalletSummary });

export const WalletSummaryProvider: FCC = ({ children }) => {
  const { userAddress } = useWeb3State();
  const { selectedPool } = usePools();
  const { assets } = useSelectedPoolAssets();
  const { prices } = usePrices();
  const { balances } = useBalances();

  const [walletSummary, setWalletSummary] = useState(initWalletSummary);

  useEffect(() => {
    if (!userAddress) clearStats();
  }, [userAddress]);

  useEffect(() => {
    if (!selectedPool || Object.keys(assets).length === 0) return;

    if (!walletSummary[selectedPool.id]) updateWalletSummary();
  }, [selectedPool]);

  useEffect(() => {
    if (!prices) return;

    updateWalletSummary();
  }, [balances, prices, assets]);

  function updateWalletSummary() {
    const { supplyingInUsd, borrowLimitInUsd } = calcTotalsBorrowLimitAndSupplying();
    const borrowingInUsd = calcBorrowingUsd();

    const accountDebtUsd = BN(borrowingInUsd).minus(borrowLimitInUsd);

    const summary = {
      accountSupplyingTotalUsd: supplyingInUsd,
      accountBorrowingTotalUsd: borrowingInUsd,
      accountBorrowLimitTotalUsd: borrowLimitInUsd,
      accountDebtUsd: accountDebtUsd.gt(0) ? accountDebtUsd.toString() : '0',
    };

    log(`summary for pool ${selectedPool.id}`, summary);

    setPoolWalletSummary(selectedPool.id, summary);
  }

  function calcTotalsBorrowLimitAndSupplying() {
    let supplyingInUsd = '0';
    let borrowLimitInUsd = '0';

    if (!assets) return { supplyingInUsd, borrowLimitInUsd };

    Object.values(assets).forEach((bToken) => {
      const address = bToken.address;
      const underlyingPrice = prices?.[address];

      if (!underlyingPrice || !bToken) return;

      const tokenSupplyingInUsd = balances?.[address]?.wallet
        ? BN(balances[address].wallet.raw.toString())
            .times(bToken.exchangeRateCurrent.toString())
            .times(prices ? underlyingPrice.raw : '0')
            .div(BN(10).pow(18 - Number(bToken.decimals)))
            .div(BN(10).pow(Number(bToken.underlying.decimals) * 2))
            .div(BN(10).pow(Number(bToken.decimals)))
        : BN(0);

      supplyingInUsd = tokenSupplyingInUsd.plus(supplyingInUsd).toString();

      if (bToken.inMarket) {
        borrowLimitInUsd = tokenSupplyingInUsd
          .times(bToken.collateralFactorMantissa.toString())
          .div(1e18)
          .plus(borrowLimitInUsd)
          .toString();
      }
    });

    return { supplyingInUsd, borrowLimitInUsd };
  }

  function calcBorrowingUsd() {
    let borrowing = '0';

    if (!assets) return borrowing;

    Object.keys(assets).forEach((address) => {
      const underlyingPrice = prices?.[address];
      const balance = balances[address];

      if (!underlyingPrice || BN(balance?.borrowing.fullPrecision).isZero()) return;

      borrowing = BN(borrowing)
        .plus(
          BN((balance?.borrowing.raw || 0).toString())
            .times(prices ? prices[address].raw : '0')
            .div(BN(10).pow(Number(assets[address].underlying.decimals) * 2)),
        )
        .toString();
    });

    return borrowing;
  }

  function clearStats() {
    setWalletSummary(initWalletSummary);
  }

  function setPoolWalletSummary(poolId: PoolId, summary: PoolWalletSummary) {
    setWalletSummary((prev) => ({
      ...prev,
      [poolId]: summary,
    }));
  }

  return (
    <AccountsProviderCtx.Provider value={{ walletSummary }}>
      {children}
    </AccountsProviderCtx.Provider>
  );
};

export const useWalletSummary = () => useContext(AccountsProviderCtx);

export const useSelectedPoolWalletSummary = () => {
  const { walletSummary } = useWalletSummary();
  const { selectedPool } = usePools();

  return walletSummary[selectedPool.id];
};
