import { AppBar, Box, BoxProps, Stack, Toolbar } from '@mui/material';
import { NavHorizontalItem } from 'src/components/nav/NavHorizontal/NavHorizontalItem';
import navConfig from 'src/components/nav/NavSection/config-navigation';
import { hideScrollbarY } from 'src/utils/cssStyles';

export const NavHorizontal = () => {
  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        position: 'initial',
        width: 'auto',
        height: 'auto',
        background: 'transparent',
        mr: { xs: 'auto', sm: 'initial' },
      }}
    >
      <Toolbar sx={{ padding: '0' }}>
        <Stack
          direction="row"
          sx={{
            mx: 'auto',
            height: 'auto',
            ...hideScrollbarY,
          }}
        >
          {navConfig.map((group, i) => (
            <NavHorizontalItem key={`${group.subheader}-${i}`} item={group} />
          ))}
        </Stack>
      </Toolbar>

      <Shadow />
    </AppBar>
  );
};

const Shadow = ({ sx, ...other }: BoxProps) => {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        ...sx,
      }}
      {...other}
    />
  );
};
