import { Stack, StackProps, Typography } from '@mui/material';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';

export const WalletStats = ({ ...props }: StackProps) => {
  const { accountBorrowLimitTotalUsd, accountBorrowingTotalUsd, accountSupplyingTotalUsd } =
    useSelectedPoolWalletSummary();

  return (
    <Stack {...props}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={'1rem'}>
        <Typography variant="subtitle1">
          Supply:{' '}
          <Typography component="span" variant="subtitle1" sx={{ color: 'text.secondary' }}>
            ${Number(accountSupplyingTotalUsd).toFixed(2)}
          </Typography>
        </Typography>
        <Typography variant="subtitle1">
          Borrow:{' '}
          <Typography component="span" variant="subtitle1" sx={{ color: 'text.secondary' }}>
            ${Number(accountBorrowingTotalUsd).toFixed(2)}
          </Typography>
        </Typography>
        <Typography variant="subtitle1">
          Borrow Limit:{' '}
          <Typography component="span" variant="subtitle1" sx={{ color: 'text.secondary' }}>
            ${Number(accountBorrowLimitTotalUsd).toFixed(2)}
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
};
