import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { DEFAULT_CHAIN_URL, DEFAULT_POOL_URL } from 'src/configs/chains.config';
import { Home } from 'src/pages/home/Home';
import { UrlPoolIdManager } from './components/UrlPoolIdManager';
import { AssetPage } from 'src/pages/assetPage/AssetPage';
import { TermsPage } from 'src/pages/terms/TermsPage';
import { UrlChainManager } from './components/UrlChainManager';
import { RouterDependedProviders } from './providers/RouterDependedProviders';
import { MainLayout } from './layout/MainLayout';

const routes: RouteObject[] = [
  {
    element: <RouterDependedProviders />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={DEFAULT_CHAIN_URL} replace />,
          },
          {
            path: ':chainUrlRepresentation',
            element: <UrlChainManager />,
            children: [
              {
                index: true,
                element: <Navigate to={DEFAULT_POOL_URL} replace />,
              },
              {
                path: ':poolId',
                element: <UrlPoolIdManager />,
                children: [
                  {
                    index: true,
                    element: <Home />,
                  },
                  { path: ':assetAddress', element: <AssetPage /> },
                  {
                    path: 'terms',
                    element: <TermsPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
