import { Box, Container } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Header } from 'src/components/header/Header';

import { FCC } from 'src/types/FCC';
import { useResponsive } from '../hooks/useResponsive';
import { HEADER } from '../theme/layout-constants';
import { NetworkAttention } from 'src/components/networkAttention/NetworkAttention';

const SPACING = 8;

export const MainLayout: FCC = () => {
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const { chainUrlRepresentation } = useParams();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        margin: '0',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(isDesktop && {
          px: 2,
          pt: `${HEADER.H_MAIN_DESKTOP + SPACING * 2}px`,
          pb: 6,
        }),
      }}
    >
      <Header />
      <Container
        sx={{
          maxWidth: { xs: 'none', xl: '60%' },
          width: '100%',
          marginTop: 0,
          overflow: 'hidden',
        }}
      >
        <Outlet />
      </Container>
      {chainUrlRepresentation && <NetworkAttention />}
    </Box>
  );
};
